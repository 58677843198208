import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { DialogSettingsStepComponent } from '@shared/components/dialog/template/dialog-settings-step/dialog-settings-step.component';
import { DialogAddStageComponent } from '@shared/components/dialog/template/dialog-add-stage/dialog-add-stage.component';
import {
  DialogSettingsFeedbackComponent
} from '@shared/components/dialog/template/dialog-settings-feedback/dialog-settings-feedback.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SelectiveEditorService } from '@modules/administration/selective-editor/selective-editor.service';
import { deepEquals, DiffObjects } from '@shared/utils/util';
import { type } from 'os';

@Injectable({
  providedIn: 'root',
})
export class SelectiveProcessModel {

  private bottomSheetIsOpen = new BehaviorSubject(null);
  isBottomSheetCheck$ = this.bottomSheetIsOpen.asObservable();

  private bottomSheetFeedBackIsOpen = new BehaviorSubject(null);
  isBottomSheetFeedbackCheck$ = this.bottomSheetFeedBackIsOpen.asObservable();

  private toogleStepFeedback = new BehaviorSubject(false);
  IsInStepOrFeedback$ = this.toogleStepFeedback.asObservable();



  constructor(
    private selectiveEditorService: SelectiveEditorService,
    private dialog: MatDialog,
    public loaderService: LoaderService
  ) { }


  public setIsBottomSheet(value) {
    this.bottomSheetIsOpen.next(value);
  }

  public setIsBottomSheetFeedback(value) {
    this.bottomSheetFeedBackIsOpen.next(value);
  }

  public setStepOrFeedback(value) {
    this.toogleStepFeedback.next(value);
  }

  /**
   * loader
   */
  public loader()  {
    return this.loaderService;
  }

  /**
   * openDialogSettingsStep
   */
  public openDialogSettingsStep(index, step, scope, callback) {
    const dialog = this.dialog.open(DialogComponent, {
      width: '1000px',
      height: '610px',
      data: {
        titleDialog: 'Configuração do passo',
        titleButton: 'Salvar',
        component: DialogSettingsStepComponent,
        contentDialogClassName: 'ps-dialog-config-step-content',
        index,
        step,
        scope
      },
      autoFocus: false
    });
    dialog.afterClosed().subscribe(result => {
      callback(result);
    });
  }

  /**
   * openDialogSettingsFeedback
   */
  public openDialogSettingsFeedback(index, feedback, scope, callback) {
    // const keys = feedback.content.filter(item => {
    //   if (item.field_id) return true;
    // }).map(item => {
    //   return {
    //     name : item.label,
    //     value: item.field_id
    //   }
    // });

    const keys = feedback.standard_fields.filter(item => {
      if (item.field_id) { return true; }
    }).map(item => {
      return {
        name : item.label,
        value: item.field_id,
        type: item.type
      };
    });

    const data = feedback;
    data.keys = keys;
    data.action = 'edit';
    const dialog = this.dialog.open(DialogComponent, {
      width: '1000px',
      height: '620px',
      data: {
        titleDialog: 'Configuração do feedback',
        titleButton: 'Salvar',
        component: DialogSettingsFeedbackComponent,
        contentDialogClassName: 'ps-dialog-config-feed-content',
        index,
        feedback,
        scope,
        data
      },
      disableClose: true,
      autoFocus: false
    });
    dialog.afterClosed().subscribe(result => {
      if (result.data.data.result) {
        callback(result);
      }
    });
  }

  public openDialogSettingsStage(dataStage, form) {
    const dialog = this.dialog.open(DialogComponent, {
      width: '1000px',
      height: '620px',
      data: {
        titleDialog: 'Configuração da etapa',
        titleButton: 'Salvar',
        component: DialogAddStageComponent,
        contentDialogClassName: 'ps-dialog-config-stage-content',
        dataStage,
        formulario: form,
        keys : dataStage.keys
      },
      disableClose: true,
      autoFocus: false
    });
  }
}
