import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { DialogComponent } from '../../dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SelectiveEditorService } from '@modules/administration/selective-editor/selective-editor.service';
import { FormUtils } from '@shared/utils/form.utils';
import { SystemSettingService } from '@modules/administration/system-settings/system-setting.service';
import { FeedbackActionsService } from '@app/services/selective-processes/feedback/feedback-actions.service';
import { uploadProgress, filterResponse } from '@shared/utils/rxjs-operators';
import { deep, updateObjKeepingRef } from '@shared/utils/util';
import { MatTabGroup } from '@angular/material/tabs';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';

@Component({
  selector: 'app-dialog-settings-feedback',
  templateUrl: './dialog-settings-feedback.component.html',
  styleUrls: ['./dialog-settings-feedback.component.scss']
})
export class DialogSettingsFeedbackComponent implements OnInit, AfterViewInit, OnDestroy {

  public fieldJson = {
    components : [{}]
  };
  public reset = [{}];
  public fieldsCompare = [];
  public definitions;
  public selectedTab = {id: 'style', name: 'Estilo'};
  public contentHeight = {
    'height' : '100%'
  };

  public feedbackCurrent;
  public feedbackCurrentBefore;
  public dataComponent: any;
  public dataSP;
  public dataCurrentStage;

  public modifiedValue;
  public unsavedStyle;
  public unsavedDataSource;
  public unsavedStatus;
  public unsavedLogic;
  public countLogic = 0;
  public invalidLogic = false;

  public progress = 0;

  @ViewChild('tabGroup', { static: false }) tabs: MatTabGroup;



  constructor(
    public dateComponent: DialogComponent,
    public dialogRef: MatDialogRef<DialogSettingsFeedbackComponent>,
    public selectiveEditorService: SelectiveEditorService,
    private systemSettingService: SystemSettingService,
    private feedbackActionsService: FeedbackActionsService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
  ) {
    this.dataComponent = dateComponent.data;
  }

  ngOnInit(): void {
    this.definitions = [
      {id: 'style', name: 'Estilo'},
      {id: 'status',  name: 'Status'},
      {id: 'logic', name: 'Lógica'},
      {id: 'data', name: 'Fonte de dados'}
    ];
    this.dataSP = this.selectiveEditorService.getData();
    this.dataCurrentStage = this.selectiveEditorService.getDataStage();
    this.feedbackCurrent = this.selectiveEditorService.getDataFeedbackID(this.dataComponent.feedback);
    this.feedbackCurrent = FormUtils.switchValueSlideToggleView([
      'logic_enabled'
    ], this.feedbackCurrent);
    this.feedbackCurrentBefore = JSON.parse(JSON.stringify(this.feedbackCurrent));
  }

  ngAfterViewInit(): void {
    this.systemSettingService.getMatTabGroup(this.tabs);
  }

  public statusChanged(obj: any) {
    this.feedbackCurrent.icon_type_id = obj.controls.conclusive.value === 0 ? 3 : obj.controls.icon_type_id.value;
    this.feedbackCurrent.conclusive = obj.controls.conclusive.value;
    this.feedbackCurrent.complementary_text = obj.controls.complementary_text.value;
    this.feedbackCurrent.cta_text = obj.controls.cta_text.value;
  }

  public logicChanged(obj: any) {
    this.verifyLogic(obj);
    this.countLogic += 1;
    this.fieldJson.components[0] = obj;
    this.feedbackCurrent.logics = obj.logics;
    this.feedbackCurrent.action_logic_id = typeof obj.action_logic_id === 'number' ? obj.action_logic_id : null;
    this.feedbackCurrent.condition_logic_id = obj.condition_logic_id;
    this.feedbackCurrent.logic_enabled = obj.logic_enabled;
    if (this.countLogic > 1) {
      this.valueChange({ changed: true, to: 'logic', data: obj });
    }
    this.redraw();
  }

  checkLogicRuleValue(logic) {
    if ([3, 4].includes(logic.rule_logic_id)) {
      return true;
    }
    if (logic.fixed_value !== undefined && logic.fixed_value !== null) {
      return true;
    }
    return Boolean(logic.fixed_value);
  }


  public verifyLogic(obj) {
    if(!obj.logic_enabled) {
      this.invalidLogic = false;
      return;
    }

    if(!obj.condition_logic_id || !obj.action_logic_id) {
      this.invalidLogic = true;
      return;
    }
    if(obj.logics.length > 0) {
      const logicIsInvalid = obj.logics.every(logic => {
        return logic.field_compare_id && logic.rule_logic_id && this.checkLogicRuleValue(logic);
      })

      this.invalidLogic = logicIsInvalid ? false : true;
    } else {
      this.invalidLogic = false;
    }
  }

  public redraw() {
    this.reset[0] = {};
  }

  tabChange(e) {
    this.selectedTab = this.definitions[e.index];
    if (this.selectedTab.id == 'style') {
      this.contentHeight = {
        height : '100%'
      };
    } else {
      this.contentHeight = {
        height : 'calc(100% - 57px)'
      };
    }
  }

  valueChange(e) {
    if (e.to === 'style') {
      this.unsavedStyle = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
      } else {
        this.dateComponent.data.scheduledClosing = false;
      }
    } else if (e.to === 'dataSource') {
      this.unsavedDataSource = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
        this.systemSettingService.setInterceptTabChange(false);
      } else {
        this.dateComponent.data.scheduledClosing = false;
        this.systemSettingService.setInterceptTabChange(true);
      }
    } else if (e.to === 'status') {
      this.unsavedStatus = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
      } else {
        this.dateComponent.data.scheduledClosing = false;
      }
    } else if (e.to === 'logic') {
      this.unsavedLogic = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
      } else {
        this.dateComponent.data.scheduledClosing = false;
      }
    }
    this.tabs.realignInkBar();
  }

  public save() {
    if (this.dataComponent.data.action === 'add') {
      // chamar serviço de salvar o campo
    } else if (this.dataComponent.data.action === 'update') {
      // chamar serviço de atualizar o campo
    } else if (this.dataComponent.data.action === 'edit') {
      this.saveDataInElement();
      // this.dialogRef.close(this.dataComponent.data);
      // retornar o campo para o editor
    }
  }

  public cancel() {
    if (this.dateComponent.data.scheduledClosing) {
      this.dialog.open(ConfirmComponent, {
        maxWidth: '60vw',
        width: '325px',
        minHeight: '210px',
        autoFocus: false,
        data: {
          title: 'Fechar configurações?',
          message: 'As alterações feitas não serão salvas.',
          btnOkText: 'Sair',
          btnCancelText: 'Cancelar',
          reverseBtn: true
        },
        disableClose: false
      }).afterClosed().subscribe(success => {
        if (success) {
          this.dataComponent.data.result = false;
          this.dialogRef.close({ event: null, data: this.dataComponent.data });
        }
      });
    } else {
      this.dataComponent.data.result = false;
      this.dialogRef.close({ event: null, data: this.dataComponent.data });
    }
  }

  saveDataInElement() {
    this.feedbackCurrent.logic_enabled = this.feedbackCurrent.logic_enabled ? 1 : 0;
    this.feedbackCurrent.condition_logic_id = this.feedbackCurrent.condition_logic_id ? this.feedbackCurrent.condition_logic_id : null;
    this.loaderService.setChangesLoaderState(true);
    if (this.feedbackCurrent.id === null) {
      delete this.feedbackCurrent.id;
      this.feedbackActionsService.upload(this.feedbackCurrent).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          updateObjKeepingRef(this.feedbackCurrent, response.data);
          this.feedbackCurrentBefore = JSON.parse(JSON.stringify(this.feedbackCurrent));
          this.valueChange({ changed: false, to: 'style', data: response.data });
          this.valueChange({ changed: false, to: 'status', data: response.data });
          this.valueChange({ changed: false, to: 'logic', data: response.data });
          this.loaderService.setChangesLoaderState(false, 'Feedback salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar o feedback, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    } else {
      this.feedbackActionsService.upload(this.feedbackCurrent).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          updateObjKeepingRef(this.feedbackCurrent, response.data);
          this.feedbackCurrentBefore = JSON.parse(JSON.stringify(this.feedbackCurrent));
          this.valueChange({ changed: false, to: 'style', data: response.data });
          this.valueChange({ changed: false, to: 'status', data: response.data });
          this.valueChange({ changed: false, to: 'logic', data: response.data });
          this.loaderService.setChangesLoaderState(false, 'Feedback salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar o feedback, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.selectiveEditorService.setDataFeedbackID(this.feedbackCurrentBefore);
    this.valueChange({ changed: false, to: 'style', data: null });
    this.valueChange({ changed: false, to: 'dataSource', data: null });
    this.valueChange({ changed: false, to: 'logic', data: null });
    this.valueChange({ changed: false, to: 'status', data: null });
  }
}
