import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewChecked,
  AfterViewInit
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  FormGroupDirective,
  NgForm,
  FormControl,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { SaveEditQueryDataService } from '@app/services/system-queries/save-edit-query-data.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SlideToggleValidator } from '@shared/validators/slide-toggle-validator';
import { mapArrayQueryRemoveDataSource, mapArrayByPropertieDataSource, deep, updateObjKeepingRef, filterSystemFields } from '@shared/utils/util';
import { DataSourceTypeFieldValidator } from '@shared/validators/data-source-type-field-validator';
import { startWith, map } from 'rxjs/operators';
import { Fields } from '@app/services/selective-processes/fields/fields';
import { ErrorStateMatcher } from '@angular/material';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { DataSourceStepFeedbackModel } from '@app/model/data-source-step-feedback.model';

export class FormCustomValidators {
  static valueSelected(myArray: any[]): ValidatorFn {

    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const selectboxValue = c.value?.label;
      const pickedOrNot = myArray.filter(alias => alias.label === selectboxValue);
      if (c.parent) {
        if (c.value?.label || c.value?.label === '') {
          if (pickedOrNot.length > 0) {
            return null;
          } else {
            return { match: true };
          }
        } else {
          return null;
        }
      }
    };
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-data-source-setting-feedback',
  templateUrl: './data-source-setting-feedback.component.html',
  styleUrls: ['./data-source-setting-feedback.component.scss']
})
export class DataSourceSettingFeedbackComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {


  @Input() dataSourceFeedback: any;
  @Input() tab: any;
  @Input() tabGroup: any;
  @Output() valueChanged = new EventEmitter<any>();

  public fieldsKey = [];
  public systemFields: Fields[] = new Array();
  public dataSource: any[];
  public haveDataSource: boolean;
  public formulario: FormGroup;
  public context: FormArray;
  public parameters: FormArray;
  public actived;

  public intervals = [
    {value: 1, name: '15 minutos'},
    {value: 2, name: '30 minutos'},
    {value: 3, name: '1 hora'},
    {value: 4, name: '2 horas'},
    {value: 5, name: '3 horas'},
    {value: 6, name: '6 horas'},
    {value: 7, name: '12 horas'},
    {value: 8, name: 'Diário'},
    {value: 9, name: 'Semanal'}
];

  public matcher = new MyErrorStateMatcher();
  public filteredOptions: Observable<Fields[]>[] = [];

  @ViewChild(MatTabGroup, { static: false }) tabs: MatTabGroup;

  constructor(
    private formBuilder: FormBuilder,
    private saveEditQueryDataService: SaveEditQueryDataService,
    private loaderService: LoaderService,
    public dataSourceStepFeedbackModel: DataSourceStepFeedbackModel,
  ) {
    this.formulario = this.formBuilder.group({
      id: [null],
      colligate: [null, Validators.required],
      system: [null, Validators.required],
      code: [null, Validators.required],
      use_cache: [null],
      cache_interval_type_id: [null],
      active_context: [false],
      lazy_load: [false],
      context: this.formBuilder.array([], SlideToggleValidator.slideToggle()),
      active_paramn: [false],
      parameters: this.formBuilder.array([], SlideToggleValidator.slideToggle())
    });
  }

  ngOnInit(): void {
    this.buildDataSource();
    this.actived = this.tab;8
    this.systemFields = this.dataSourceFeedback.data.scope.fieldsStandard;
  }

  ngAfterViewInit(): void {
    this.dataSourceStepFeedbackModel.valueOne = this.formulario.value;
    this.dataSourceStepFeedbackModel.valueTwo = undefined;
    if (this.tabs) {
      this.dataSourceStepFeedbackModel.getMatTabGroup(this.tabs);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.actived = changes.tab.currentValue;
  }

  ngAfterViewChecked(): void {
    if (this.dataSourceStepFeedbackModel.status()) {
      this.valueChanged.emit({ changed: true, to: 'dataSource', data: this.formulario.getRawValue() });
    } else {
      this.valueChanged.emit({ changed: false, to: 'dataSource', data: null });
    }
  }

  onSubmit(currentValue) {
    const sendServe = this.formulario.getRawValue();
    if (sendServe.id === null) {
      delete sendServe.id;
    }
    sendServe.fields = mapArrayQueryRemoveDataSource(currentValue);
    sendServe.feedback_id = this.dataSourceFeedback.dados.feedback.id;
    sendServe.local_id = 7;
    sendServe.type_id = 2;
    sendServe.title = 'Consulta Personalizada';
    this.formatParametersToSubmit(sendServe, 'parameters', 'active_paramn');
    this.formatParametersToSubmit(sendServe, 'context', 'active_context');
    
    this.loaderService.setChangesLoaderState(true);
    this.saveEditQueryDataService.create(sendServe).subscribe(
      data => {
        if (data.success) {
          this.updateValueQuery(data.data);
          this.dataSourceStepFeedbackModel.valueOne = this.formulario.getRawValue();
          this.valueChanged.emit({ changed: false, to: 'dataSource', data: data.data });
          this.loaderService.setChangesLoaderState(false,
            'Fonte de dados salva com sucesso',
            'ok',
            'success');
        } else {
          this.loaderService.setChangesLoaderState(false,
            'Não foi possível configurar a consulta, tente novamente mais tarde !',
            'ok',
            'alert');
        }
      },
      error => {
        this.loaderService.setChangesLoaderState(false,
          error.body,
          '',
          'error',
          error.title,
          100000);
      }
    );
  }

  private formatParametersToSubmit(data, formName, toogleName) {
    if (data[toogleName]) {
      data[formName] = data[formName].length > 0 ? mapArrayByPropertieDataSource('field_id', data[formName]) : [];
    } else {
      data[formName] = [];
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: '',
      name: ['', [Validators.required]],
      parameter_type_id: ['', Validators.required],
      field_id: ['', [DataSourceTypeFieldValidator.valueFieldId(), FormCustomValidators.valueSelected(this.systemFields)]],
      fixed_value: ['', DataSourceTypeFieldValidator.valueFixedValue()]
    });
  }

  addItem(array: Array<any> = [], formName): void {
    this[formName] = this.formulario.get(formName) as FormArray;
    if (array.length === 0) {
      this[formName].push(this.createItem());
      this.manageNameControl(this[formName].length - 1, formName);
    } else {
      for (const key in array) {
        if (array.hasOwnProperty(key)) {
          const element = array[key];
          this[formName].push(this.formBuilder.group({
            id: element.id,
            name: [element.name, Validators.required],
            parameter_type_id: [String(element.parameter_type_id), Validators.required],
            field_id: [this.buildSelectedAutocompleteOption(element.field_id, this.systemFields), [
              FormCustomValidators.valueSelected(this.systemFields),
              DataSourceTypeFieldValidator.valueFieldId()
            ]],
            fixed_value: [element.fixed_value, DataSourceTypeFieldValidator.valueFixedValue()],
          }));
          this.manageNameControl(this[formName].length - 1, formName);
        }
      }
    }
  }

  addItemSpecificPosition(formName, indice): void {
    indice++;
    this[formName] = this.formulario.get(formName) as FormArray;
    this[formName].insert(indice, this.createItem());
    this.manageNameControl(indice, formName);
  }

  manageNameControl(index: number, formName) {
    this.filteredOptions[index] = this[formName].at(index).get('field_id').valueChanges
      .pipe(
        startWith(''),
        map(option => option ? filterSystemFields({ value: option as any, systemFields: this.systemFields}) : this.systemFields.slice())
      );
  }

  changeParameterTypeId(formName) {
    this[formName].controls.forEach(control => {
      const controlAny: any = control;
      controlAny.controls.fixed_value.updateValueAndValidity();
      controlAny.controls.field_id.updateValueAndValidity();
    });
  }

  private updateValueQuery(data) {
    const query = this.dataSourceFeedback.dados.scope.feedbackQuerys;
    const obj = query.find(o => o.id === data.id);
    if (typeof obj === 'undefined') {
      query.push(data);
    } else {
      updateObjKeepingRef(obj, data);
    }
    this.setFormulario([data]);
    this.buildQueryReturn();
  }

  buildSelectedAutocompleteOption(id, options) {
    if (typeof id !== 'object') {
      if (options && options.length) {
        for (const iterator of options) {
          if (iterator.field_id === id) {
            return iterator;
          }
        }
      }
      return null;
    } else {
      return id;
    }
  }

  removeItem(i, name): void {
    const { formName, toggleName } = this.getParameterInfo(name);
    if (this[formName].length === 1) {
      this.formulario.patchValue({ [toggleName]: false });
    }
    this[formName].removeAt(i);
  }

  /**
   * changeToggle
   */
   public changeToggle(e, name) {
    const { formName } = this.getParameterInfo(name);
    if (!e.checked) {
      this.setValidatorsParameters(formName);
      if (this.formulario.value[formName].length === 0) {
        this.addItem([], formName);
      }
    } else {
      if (typeof this[formName] !== 'undefined') {
        if (typeof this[formName].controls !== 'undefined') {
          this[formName].controls.map((ctrl: any) => {
            for (const key in ctrl.controls) {
              if (ctrl.controls.hasOwnProperty(key)) {
                const element = ctrl.controls[key];
                element.clearValidators();
                element.updateValueAndValidity();
              }
            }
          });
          this[formName].clear();
        }
      }
    }
  }

  public actionTabs(e) {
    this.setFormulario(this.dataSource[e.selectedIndex]);
    this.dataSourceStepFeedbackModel.valueOne = this.formulario.value;
    this.formulario.valueChanges.subscribe(
      changed => {
        this.dataSourceStepFeedbackModel.valueTwo = changed;
      }
    );
  }

  private buildDataSource() {
    const fields: Array<any> = this.dataSourceFeedback.dados.scope.feedbackFields;
    const obj = [];
    fields.forEach((field) => {
      if (field.predefined_source_options === 1 && field.defined_source === 1) {
        if (field.relation_id !== null) {
          if (obj[field.relation_id]) {
            obj[field.relation_id].push(field);
          } else {
            obj[field.relation_id] = [field];
          }
        } else {
          obj.push([field]);
        }
      }
    });
    this.dataSource = obj.filter(elm => elm);
    if (Object.keys(obj).length === 0) {
      this.haveDataSource = false;
    } else {
      this.haveDataSource = true;
    }
    this.buildQueryReturn();
  }

  private buildQueryReturn() {
    const data: Array<any> = this.dataSourceFeedback.dados.scope.feedbackQuerys;
    for (const query of data) {
      for (const fields of query.fields_query) {
        this.mergeDataReturnQuery(query, fields);
      }
    }
  }

  private mergeDataReturnQuery(query, field) {
    for (const key in this.dataSource) {
      if (this.dataSource.hasOwnProperty(key)) {
        const element: any = this.dataSource[key];
        for (const dataSoruce of element) {
          if (field.field_settings_id === dataSoruce.field_settings_id) {
            const obj = element.find(o => o.code === query.code);
            if (typeof obj === 'undefined') {
              element.push(query);
            }
          }
        }
      }
    }
  }

  private setFormulario(data) {
    data.filter((e) => {
      if (e.code) {
        this.formatParameters(e, 'parameters');
        this.formatParameters(e, 'context');
        this.formulario.patchValue(e);

        this.setParametersForm(e, 'parameters');
        this.setParametersForm(e, 'context');

      } else {
        this.formulario.reset();
        this.formulario.patchValue({ active_paramn: false, active_context: false });
        this.resetParameters('parameters');
        this.resetParameters('context');
      }
    });
  }

  private formatParameters(form, parameterName) {
    for (const param of form[parameterName]) {
      param.parameter_type_id = String(param.parameter_type_id);
      param.field_id = this.buildSelectedAutocompleteOption(param.field_id, this.systemFields);
    }
  }
  
  private setParametersForm(form, parameterIdentification){
    const { formName, toggleName } = this.getParameterInfo(parameterIdentification);
    this.resetParameters(formName);
    if (form[formName]?.length > 0) {
      this.formulario.patchValue({[toggleName]: true });
      this.addItem(form[formName], formName);
    }
  }

  private resetParameters(formName) {
    if (typeof this[formName] !== 'undefined') {
      this[formName].controls = [];
      this[formName].reset([]);
    }
  }

  private setValidatorsParameters(formName) {
    if (typeof this[formName] !== 'undefined') {
      this[formName].controls.map((ctrl: any) => {
        for (const key in ctrl.controls) {
          if (ctrl.controls.hasOwnProperty(key)) {
            const element = ctrl.controls[key];

            if (key === 'name') {
              element.setValidators(Validators.required);
              // element.updateValueAndValidity();
            }
            if (key === 'parameter_type_id') {
              element.setValidators(Validators.required);
              // element.updateValueAndValidity();
            }
            if (key === 'fixed_value') {
              element.setValidators(DataSourceTypeFieldValidator.valueFixedValue());
              // element.updateValueAndValidity();
            }
            if (key === 'field_id') {
              // tslint:disable-next-line: max-line-length
              element.setValidators([DataSourceTypeFieldValidator.valueFieldId(), FormCustomValidators.valueSelected(this.systemFields)]);
              // element.updateValueAndValidity();
            }
            element.markAsUntouched();
            // element.markAsPristine();
          }
        }
      });
    }
  }

  displayFn(field?: Fields): string | undefined {
    return field ? field.label : undefined;
  }

  private getParameterInfo(value) {
    const data = [
      {
        toggleName: 'active_paramn',
        formName: 'parameters',
      },
      {
        toggleName: 'active_context',
        formName: 'context',
      }
    ]

    return data.find(e => e.toggleName === value || e.formName === value);
  }

  get p() { return this.formulario.get('parameters') as FormArray; }
  get c() { return this.formulario.get('context') as FormArray; }
  get f() { return this.formulario.controls; }
}
