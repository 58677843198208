import { Resource } from './resource.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Serializer } from './serializer';
import { Observable, throwError, Subject, pipe, forkJoin } from 'rxjs';

import { map, tap, catchError, take, delay, filter, shareReplay } from 'rxjs/operators';
import { QueryOptions } from './query-options';
import { NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { isArray } from 'util';
import { environment } from '@env';

export class ResourceService<T extends Resource> {
  constructor(
    public httpClient: HttpClient,
    public url: string,
    public endpoint: any,
    public serializer: Serializer
  ) {
    this.getUrlcurrent(endpoint);
  }

  public create(item): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, item, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map(data => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public createNoParam(): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, null, options)
      .pipe(
        take(1),
        map(data => this.serializer.fromJson(data) as T),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        catchError(this.handleError)
      );
  }

  public createParamUrl(param): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}/${param}`, null, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map(data => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public createParamUrlAndBody(param, body): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}/${param}`, body, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map(data => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public multipleCreate(item) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    const allQueries = item.map(option => {
      return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, option, options)
        .pipe(
          map(data => this.serializer.fromJson(data) as T),
          catchError(this.handleError)
        );
    });
    return forkJoin(allQueries);
  }

  public createArray(item): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, item, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map(data => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public createArrayNoParam(): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, null, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map(data => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public update(item: T): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .put<T>(`${this.url}/${this.endpoint}/${item.id}`,
        this.serializer.toJson(item), options)
      .pipe(
        take(1),
        map((data: any) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public updateBody(item: T): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .put<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item), options)
      .pipe(
        take(1),
        map(data => this.serializer.fromJson(data) as T),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        catchError(this.handleError)
      );
  }

  public updateTwoParam(item: T): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .put<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item), options)
      .pipe(
        take(1),
        map(data => this.serializer.fromJson(data) as T),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        catchError(this.handleError)
      );
  }

  public upload(file) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      }),
      reportProgress: true,
      observe: 'events' as 'body'
    };

    return this.httpClient.post(`${this.url}/${this.endpoint}`, this.toFormData(file), options);
  }

  downloadFile(id): Observable<Blob> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS')
      }),

      // Ignore this part or  if you want full response you have
      // to explicitly give as 'boby'as http client by default give res.json()
      observe: 'response' as 'body',

      // have to explicitly give as 'blob' or 'json'
      responseType: 'blob' as 'blob'
    };
    return this.httpClient
      .get(`${id}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map((resObj: Blob) => resObj),
        catchError(this.handleError)
      );
  }


  public read(param): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}/${param}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        // map((data: any) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public readParam(param, body: object): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      }),
      params: Object.getOwnPropertyNames(body)
        .reduce((p, key) => p.set(key, body[key]), new HttpParams())
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}/${param}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        map((data: any) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public readParamPost(param, body: object): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      }),
      params: Object.getOwnPropertyNames(body)
        .reduce((p, key) => p.set(key, body[key]), new HttpParams())
    };
    return this.httpClient
      .post(`${this.url}/${this.endpoint}/${param}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        map((data: any) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public readBasic(): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        map((data: T) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public listAll(): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}`, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map((data: T) => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public listAllFullResponse(): Observable<{ data: T[], response: any }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}`, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map((data) => {
          const response = { ...data } as any;
          delete response.data;
          return {
            data: this.convertData(data),
            response
          }
        }),
        catchError(this.handleError)
      );
  }

  public hasData(): Observable<Boolean> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        map((data: any) => this.checkHasResult(data)),
        catchError(this.handleError)
      );
  }

  public list(queryOptions): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get<T[]>(`${this.url}/${this.endpoint}?${QueryOptions.toQueryString(queryOptions)}`, options)
      .pipe(
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        take(1),
        map((data: any) => this.convertData(data.data)),
        catchError(this.handleError)
      );
  }

  public listParam(param): Observable<T[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .get(`${this.url}/${this.endpoint}/${param}`, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map((data: T) => this.convertData(data) as T[]),
        catchError(this.handleError)
      );
  }

  public delete(id: T): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      })
    };
    return this.httpClient
      .delete(`${this.url}/${this.endpoint}/${id}`, options)
      .pipe(
        take(1),
        tap((log) => {
            if(!environment.production) {
              console.log(log)
            } 
          }),
        map((data: any) => this.serializer.fromJson(data) as T),
        catchError(this.handleError)
      );
  }

  public uploadProgress(cb: (progress: number) => void) {
    return tap((event: HttpEvent<T>) => {
      if (event.type === HttpEventType.UploadProgress) {
        cb(Math.round((100 * event.loaded) / event.total));
      }
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public toResponseBody<T>() {
    return pipe(
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((res: HttpResponse<T>) => res.body)
    );
  }

  public postApplymentDataWithFormData(item): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('tokenPS'),
      }),
      reportProgress: true,
      observe: 'events' as 'body'
    };

    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, this.findFilesAndCreateFormData(item), options);
  }

  public findFilesAndCreateFormData(formValue: any) {
    const formData = new FormData();

    this.putFileInFormData(formValue, formData);

    for (const key of Object.keys(formValue)) {
      if (Array.isArray(formValue[key])) {
        formData.append(key, JSON.stringify(formValue[key]));
      } else {
        formData.append(key, formValue[key]);
      }
    }
    return formData;
  }

  public putFileInFormData(formValue, formData){
    let fileCount = 0;
    formValue.data.forEach(item => {
      if (item.type === 'upload') {
        if (Array.isArray(item.value)) {
          item.value.forEach(fileItem => {
            if (fileItem.files && fileItem.files.length > 0) {
              fileItem.value = [];
              for (let index = 0; index < fileItem.files.length; index++) {
                const file = fileItem.files[index];
                if (file instanceof File) {
                  formData.append(String('file_' + fileCount), file);
                  fileItem.value.push(String('file_' + fileCount));
                  fileCount++;
                }
              }
            } else {
              fileItem.value = null;
            }
          });
        } else {
          item.value = []
        }
      }
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      if (formValue[key] instanceof File) {
        const value = formValue[key];
        formData.append(key, value);
      } else if (Array.isArray(formValue[key])) {
        formData.append(key, JSON.stringify(formValue[key]));
      } else {
        formData.append(key, formValue[key]);
      }
    }

    return formData;
  }

  private convertData(data: any): T[] {
    if (typeof data.success !== 'undefined') {
      if (data.success &&  Array.isArray(data.data)) {
        return data.data.map(item => this.serializer.fromJson(item));
      } else {
        return data;
      }
    } else if (isArray(data)) {
      return data;
    }
  }

  private getUrlcurrent(end) {
    if (typeof end.router === 'object' && typeof end.router !== 'undefined') {
      this.firstTimeIn(end);
      end.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: any) => {
        const eventPop = event.url.split('/').pop();
        if (eventPop.indexOf('-') !== -1) {
          this.endpoint = `${end.endpoint}/${eventPop.split('-')[end.splitPostion]}`;
        } else {
          this.endpoint = `${end.endpoint}/${eventPop}`;
        }
      });
    }
  }

  private checkHasResult(data): boolean {
    if (typeof data.success !== 'undefined') {
      if (data.success) {
        if(Array.isArray(data.data)) {
          return data.data.length > 0;
        } else {
          return !!data.data;
        }
      }
    }
   return false;
  }

  private firstTimeIn(end) {
    const eventPop = end.router.url.split('/').pop();
    if (eventPop.indexOf('-') !== -1) {
      this.endpoint = `${end.endpoint}/${eventPop.split('-')[end.splitPostion]}`;
    } else {
      this.endpoint = `${end.endpoint}/${eventPop}`;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error?.message}`);
      console.error(error);

    }
    // return an observable with a user-facing error message
    return throwError({
      title: `ERROR ${error.status}`,
      body: 'Tente novamente ou contate nosso time de suporte para solicitar ajuda.'
    });
  }
}
